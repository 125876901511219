import { addId, formatCurrency } from '@/utilities/functions'
import { getFormattedDate } from '@/utilities/org'
import { isBefore } from '@/utilities/time'
import { DateTime } from 'luxon'

export const getDateOfFirstRegistrationFromContext = (context: Record<string, any>): DateTime => {
  const { values: v } = context
  return DateTime.fromISO(v[2]?.dateOfFirstRegistration || v?.dateOfFirstRegistration || '')
}

export const infoBoxes = (context: Record<string, any>): Record<string, any>[] => {
  const details: Record<string, any> = context.location || context.permit || {}
  const {
    applicationEndDate,
    costNet,
    additionalDriverCostNet,
    companionAppAnnualCostNet,
    installmentCount,
    installmentCostNet,
  } = details

  const boxes = []

  if (context.isAdditionalDriver) {
    if (isWhiteTicket(details)) {
      boxes.push({
        title: 'Additional Driver Net Cost (Including Companion App Cost)',
        text: formatCurrency(additionalDriverCostNet + companionAppAnnualCostNet),
      })
    } else {
      boxes.push({
        title: 'Additional Driver Net Cost',
        text: formatCurrency(additionalDriverCostNet),
      })
    }
  } else {
    if (isWhiteTicket(details)) {
      boxes.push({
        title: 'Permit Net Cost (Including Companion App Cost)',
        text: formatCurrency(costNet + companionAppAnnualCostNet),
      })
    } else {
      boxes.push({ title: 'Permit Net Cost', text: formatCurrency(costNet) })
    }
  }

  if (canPayInInstallments(details)) {
    if (context.isAdditionalDriver) {
      boxes.push({
        title: 'Installments',
        text: installmentCount,
      })
      boxes.push({
        title: 'Installment Net Cost',
        text: formatCurrency(
          (additionalDriverCostNet + companionAppAnnualCostNet) / installmentCount
        ),
      })
    } else {
      boxes.push({
        title: 'Installments',
        text: installmentCount,
      })
      boxes.push({
        title: 'Installment Net Cost',
        text: formatCurrency(installmentCostNet),
      })
    }
  }

  boxes.push({
    title: 'Application Closing Date',
    text: getFormattedDate(applicationEndDate),
  })

  return addId(boxes)
}

export const isWhiteTicket = (details: Record<string, any>): boolean => {
  return !!details?.isWhiteTicketLocation
}

export const canPayInInstallments = (details: Record<string, any>): boolean => {
  return !!details?.canPayInInstallments
}

export const motCertificateRequired = (context: Record<string, any>): boolean => {
  return vehicleIsOverThreeYearsOld(context)
}

export const vehicleIsOverThreeYearsOld = (context: Record<string, any>): boolean => {
  const dateOfFirstRegistration = getDateOfFirstRegistrationFromContext(context)
  const threeYearsAgo = DateTime.now().minus({ years: 3 })
  if (!dateOfFirstRegistration.isValid || !threeYearsAgo.isValid) return false
  const isOlderThan3YearsOld = isBefore(dateOfFirstRegistration, threeYearsAgo)
  return isOlderThan3YearsOld
}

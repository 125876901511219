
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CancelWarningPrompt extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  value!: boolean

  // methods // ***
  onCancel() {
    this.$emit('cancel')
  }
  onConfirm() {
    this.$emit('confirm')
  }
}

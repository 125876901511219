import {
  exactChar,
  isNumeric,
  isEmailAddress,
  isFuture,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isPast,
  isRequired,
  isValidDate,
  maxChar,
  minChar,
} from '@/utilities/validations'
import { DateTime } from 'luxon'
import { PERMIT_FORM_MODES } from '@/utilities/constants'

export const driverInformation = (): Record<string, any> => {
  return {
    firstName: { label: 'First Name', rules: [isRequired] },
    lastName: { label: 'Last Name', rules: [isRequired] },
    additionalDriver: { label: 'Tick if you are an Additional Driver' },
    addressAutocomplete: { label: 'Address' },
    mobilePhoneNumber: {
      label: 'Mobile Phone Number',
      rules: [isRequired, maxChar(15), minChar(10), isNumeric],
    },
    personalEmailAddress: { label: 'Email', rules: [isEmailAddress] },
    dvlaDriversLicenceNumber: {
      label: "DVLA Driver's License Number",
      rules: [isRequired, exactChar(16)],
    },
  }
}

export const supportingDocuments = (context: Record<string, any>): Record<string, any> => {
  const dateIsAllowed = (d: string) => isFuture(d) === true
  const defaultDocumentProps = () => ({
    counter: true,
    'show-size': true,
    'small-chips': true,
    'truncate-length': 30,
  })
  const { hasAmendmentFee, mode } = context
  const { AMEND, APPLY } = PERMIT_FORM_MODES
  const requireFiles = mode === APPLY || (mode === AMEND && hasAmendmentFee)
  const rules: Record<string, any> = {
    hackneyCarriageDriverLicence: [],
    hackneyCarriageDriverLicenceExpiryDate: [isFuture, isValidDate],
    hackneyCarriageVehicleLicence: [],
    hackneyCarriageVehicleLicenceExpiryDate: [isFuture, isValidDate],
    hireAndRewardVehicleInsuranceCertificate: [],
    hireAndRewardVehicleInsuranceCertificateExpiryDate: [isFuture, isValidDate],
    motCertificate: [],
    motCertificateExpiryDate: [isFuture, isValidDate],
    recentColourPhoto: [],
  }
  const textFieldRules: Record<string, any> = {
    hackneyCarriageDriverLicenceExpiryDate: [],
    hackneyCarriageVehicleLicenceExpiryDate: [],
    hireAndRewardVehicleInsuranceCertificateExpiryDate: [],
    motCertificateExpiryDate: [],
  }
  if (requireFiles)
    Object.keys(rules).forEach(k => {
      rules[k].push(isRequired)
    })
  else {
    const {
      hackneyCarriageDriverLicence,
      hackneyCarriageVehicleLicence,
      hireAndRewardVehicleInsuranceCertificate,
      motCertificate,
    } = context.values || {}
    if (hackneyCarriageDriverLicence) {
      rules.hackneyCarriageDriverLicenceExpiryDate.push(isRequired)
      textFieldRules.hackneyCarriageDriverLicenceExpiryDate.push(isRequired)
    }
    if (hackneyCarriageVehicleLicence) {
      rules.hackneyCarriageVehicleLicenceExpiryDate.push(isRequired)
      textFieldRules.hackneyCarriageVehicleLicenceExpiryDate.push(isRequired)
    }
    if (hireAndRewardVehicleInsuranceCertificate) {
      rules.hireAndRewardVehicleInsuranceCertificateExpiryDate.push(isRequired)
      textFieldRules.hireAndRewardVehicleInsuranceCertificateExpiryDate.push(isRequired)
    }
    if (motCertificate) {
      rules.motCertificateExpiryDate.push(isRequired)
      textFieldRules.motCertificateExpiryDate.push(isRequired)
    }
  }
  return {
    hackneyCarriageDriverLicence: {
      ...defaultDocumentProps(),
      label: 'Hackney Carriage Driver Licence (Paper Part)',
      placeholder: 'Please select a file to upload...',
      rules: rules.hackneyCarriageDriverLicence,
    },
    hackneyCarriageDriverLicenceExpiryDate: {
      'allowed-dates': dateIsAllowed,
      label: 'HCDL Expiry Date',
      value: DateTime.now(),
      'text-field-rules': textFieldRules.hackneyCarriageDriverLicenceExpiryDate,
    },
    hackneyCarriageVehicleLicence: {
      ...defaultDocumentProps(),
      label: 'Hackney Carriage Vehicle Licence (Paper Part)',
      placeholder: 'Please select a file to upload...',
      rules: rules.hackneyCarriageVehicleLicence,
    },
    hackneyCarriageVehicleLicenceExpiryDate: {
      'allowed-dates': dateIsAllowed,
      label: 'HCVL Expiry Date',
      value: DateTime.now(),
      'text-field-rules': textFieldRules.hackneyCarriageVehicleLicenceExpiryDate,
    },
    hireAndRewardVehicleInsuranceCertificate: {
      ...defaultDocumentProps(),
      label: 'Hire & Reward Vehicle Insurance Certificate',
      placeholder: 'Please select a file to upload...',
      rules: rules.hireAndRewardVehicleInsuranceCertificate,
    },
    hireAndRewardVehicleInsuranceCertificateExpiryDate: {
      'allowed-dates': dateIsAllowed,
      label: 'HRVIC Expiry Date',
      value: DateTime.now(),
      rules: rules.hireAndRewardVehicleInsuranceCertificateExpiryDate,
      'text-field-rules': textFieldRules.hireAndRewardVehicleInsuranceCertificateExpiryDate,
    },
    motCertificate: {
      ...defaultDocumentProps(),
      label: 'MOT Certificate',
      placeholder: 'Please select a file to upload...',
      rules: rules.motCertificate,
    },
    motCertificateExpiryDate: {
      'allowed-dates': dateIsAllowed,
      label: 'MOT Expiry Date',
      value: DateTime.now(),
      'text-field-rules': textFieldRules.motCertificateExpiryDate,
    },
    recentColourPhoto: {
      ...defaultDocumentProps(),
      accept: 'image/png, image/jpg, image/jpeg, image/jfif, image/bmp',
      hint: '(Please ensure the photo is the correct orientation, i.e., not upside down, so it prints correctly.)',
      label: 'Recent Colour Photo',
      'persistent-hint': true,
      placeholder: 'Please select a file to upload...',
      rules: rules.recentColourPhoto,
    },
  }
}

export const termsAndConditions = (): Record<string, any> => ({
  plyForHireTermsAndConditions: {
    label: 'Ply for Hire Terms and Conditions',
    rules: [(v: boolean) => v === true || 'Terms and Conditions must be accepted to continue.'],
  },
  whiteTicketTermsAndConditions: {
    label: 'White Ticket Terms and Conditions',
    rules: [(v: boolean) => v === true || 'Terms and Conditions must be accepted to continue.'],
  },
  driverCompanionAppTerms: {
    label: 'Driver Companion App Terms',
    rules: [(v: boolean) => v === true || 'Terms and Conditions must be accepted to continue.'],
  },
  igoMarketplaceTermsAndConditions: {
    label: 'Igo Marketplace Terms and Conditions',
    rules: [(v: boolean) => v === true || 'Terms and Conditions must be accepted to continue.'],
  },
  permitRatesTermsAndConditions: {
    label: 'Permit Rates Terms and Conditions',
    rules: [(v: boolean) => v === true || 'Terms and Conditions must be accepted to continue.'],
  },
})

export const vehicleInformation = (): Record<string, any> => ({
  // capability: {
  //   label: 'Capability',
  //   items: [
  //     { text: 'Please select a vehicle capability...', value: 0 },
  //     { text: 'Saloon', value: 'saloon' },
  //   ],
  //   rules: [isRequired],
  //   value: 0,
  // },
  make: { label: 'Make', rules: [isRequired] },
  model: { label: 'Model', rules: [isRequired] },
  colour: { label: 'Colour', rules: [isRequired] },
  dateOfFirstRegistration: {
    'allowed-dates': (d: string) => isPast(d) === true,
    label: 'Date of First Registration',
    rules: [isRequired, isPast],
  },
  availableSeats: {
    label: 'Available Seats (excluding driver)',
    rules: [isRequired, isLessThanOrEqualToN(32), isGreaterThanOrEqualToN(1)],
  },
  registration: { label: 'Registration', rules: [isRequired] },
  hcdlNumber: { label: 'HCDL Number', rules: [isRequired] },
  plateNumber: { label: 'Plate Number', rules: [isRequired] },
  isWav: { label: 'Tick if your vehicle is wheelchair accessible' },
})
